import clearIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/clear-icon.svg';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { Button, SxProps } from '@mui/material';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { useRef } from 'react';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';

type ClearButtonProps = {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    sx?: SxProps;
};

const buttonTitle = 'Clear';

export function ClearButton({ onClick, sx }: ClearButtonProps) {
    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);
    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            <Button
                variant='outlined'
                color='primary'
                onClick={(e) => {
                    analyticsDataPickerRef.current?.registerAction({
                        action: joinWithDelimiter({
                            values: [`click on ${buttonTitle}`],
                        }),
                    });
                    onClick?.(e);
                }}
                sx={{
                    textTransform: 'none',
                    borderColor: 'transparent',
                    '&:hover': {
                        borderColor: 'transparent',
                        backgroundColor: 'transparent',
                    },
                    fontFamily: fontFamilies.GraphikRegular,
                    fontSize: '13.5px',
                    ...sx,
                }}>
                <img src={clearIcon} alt='Clear Icon' style={{ width: '26px', height: '24px', marginRight: '12px' }} />
                {buttonTitle}
            </Button>
        </>
    );
}
