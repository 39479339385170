import styled from '@emotion/styled';
import { Paper, SxProps } from '@mui/material';
import { ITooltipParams } from 'ag-grid-community';
import { fontFamilies } from '../../utils';
import parse from 'html-react-parser';

type CssPositionalProps = { top?: string; bottom?: string; left?: string; right?: string };

type AgGridCustomTooltipProps = ITooltipParams & {
    text?: string;
    arrow?: boolean;
    arrowPosition?: CssPositionalProps;
    allowHtml?: boolean;
    tooltipLayoutStyle?: SxProps;
};

export function AgGridCustomTooltip(props: AgGridCustomTooltipProps) {
    const { text = '', arrow = false, arrowPosition } = props;
    const TooltipLayout = styled(Paper)({
        backgroundColor: '#fff',
        color: '#57626a',
        fontSize: '14px',
        fontFamily: fontFamilies.GraphikRegular,
        padding: '12px 24px 12px 24px',
        borderRadius: '4px',
        filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        whiteSpace: 'normal'
    });

    const ArrowPointer = styled('div')({
        content: '""',
        position: 'absolute',
        left: arrowPosition?.left || 'unset',
        right: arrowPosition?.right || 'unset',
        top: arrowPosition?.top || 'unset',
        bottom: arrowPosition?.bottom || 'unset',
        border: '8px solid #000',
        borderColor: 'transparent transparent #fff transparent',
    });
    
    return (
        <TooltipLayout sx={props.tooltipLayoutStyle || {}}>
            {text}
            {props.allowHtml? parse(props.value): props.value}
            {arrow && <ArrowPointer />}
        </TooltipLayout>
    );
}
