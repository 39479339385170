import { determineSendSingleRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import {
    determineGetData,
    determinePrefetchQuery,
    determineUseData,
    determineUseMultipleData,
} from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiInstanceTypes, ApiNames } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { API } from 'aws-amplify';

export const getData = determineGetData({
    apiNameToApiDetails: {
        [ApiNames.PlatformManagementInternal]: {
            instance: API,
            instanceType: ApiInstanceTypes.AwsAmplify,
        },
    },
});

export const UseData = determineUseData(getData);
export const UseMultipleData = determineUseMultipleData(getData);
export const prefetchQuery = determinePrefetchQuery(getData);
export const sendSingleRequest = determineSendSingleRequest({ UseData });
