import NewBadge from '@cfra-nextgen-frontend/shared/src/assets/images/NewBadge.svg';
import {
    CellRendererValueProcessor,
    getCellRenderer,
    getCompanyDetailsLinkRenderer,
} from '@cfra-nextgen-frontend/shared/src/components/AgGrid/renderers';
import { ColumnDef } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { Rating } from '@cfra-nextgen-frontend/shared/src/components/Rating/Rating';
import {
    getFilterKeysToRowLevelFilter,
    getObjectWithFilter,
    getRowLevelFilterCellRenderer,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/rowFilters';
import {
    IViewDataFields,
    ScreenerData,
    ScreenerEtfData,
    ScreenerResearchCompanyData,
    ScreenerResearchData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { MetadataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';
import {
    ComponentFilterParams,
    ViewdataFieldDefinition,
} from '@cfra-nextgen-frontend/shared/src/components/types/fieldViewData';
import {
    CellRendererComponentFilters,
    getValuesByPath,
    getValuesByPathDotsIndependent,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { getMomentObjectFrom, standardDateFormat } from '@cfra-nextgen-frontend/shared/src/utils/time';
import { Box } from '@mui/material';
import { ICellRendererParams, ITooltipParams, ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';
import { AgGridCustomTooltip } from '../../AgGrid/AgGridCustomTooltip';
import { keepNoLeftPaddingCellClassRules, keepNoSidePaddingsCellClassRules } from '../../AgGrid/utils';
import { PopoverGrid } from '../components/PopoverGrid';
import { getExcelExportDateFormat, getExcelNumberFormat } from './excelExport';
import { defaultHeaderTemplate, fillTemplate } from './templates';
import { defaultNoRatingText, defaultNoResultsSymbol, getScreenerValueFormatter } from './valueFormatters';
import { getScreenerNumberFilterValueGetter } from './valueGetters';

function sortViewdataAsc(viewdataFields: Array<IViewDataFields>): Array<IViewDataFields> {
    return viewdataFields.sort((n1, n2) => Object.values(n1)[0].order - Object.values(n2)[0].order);
}

function getHeaderTemplate(header_template?: string, symbol?: string) {
    return !header_template && symbol === '$' ? defaultHeaderTemplate : header_template;
}

function getHeaderName(fieldMetadata: MetadataFieldDefinition, headerTemplate?: string) {
    let result = fieldMetadata.label;

    if (headerTemplate) {
        result =
            fillTemplate({
                templateName: 'header_template',
                template: headerTemplate,
                dataObject: fieldMetadata,
            }) || result;
    }

    return result;
}

type CellRendererValueProcessorProps = {
    fieldMetadata: MetadataFieldDefinition;
    fieldViewdata: ViewdataFieldDefinition;
    outerGetCellRendererValueProcessor?: CellRendererValueProcessorGetter;
};

export type CellRendererValueProcessorGetter = (props: CellRendererValueProcessorProps) => CellRendererValueProcessor;

export const getCellRendererValueProcessor: CellRendererValueProcessorGetter = (outerProps) => {
    const { fieldMetadata, outerGetCellRendererValueProcessor } = outerProps;
    return (innerProps) => {
        const { resultChild, component, resultValue, param } = innerProps;
        switch (component) {
            case 'rating':
                if (!resultChild) {
                    return fieldMetadata.no_value_symbol || defaultNoRatingText;
                }
                return <Rating value={Number(resultChild)} />;

            case 'list':
                return <Box>{resultChild}</Box>;

            case 'new_badge':
                let applyBadge: boolean = false;

                if (
                    param?.component_filter === CellRendererComponentFilters.IS_LAST_ROLLING_HOURS &&
                    resultValue !== defaultNoResultsSymbol
                ) {
                    applyBadge = isLastRollingHours(resultValue, param?.component_filter_params);
                }

                if (!applyBadge) {
                    return resultChild;
                }

                return (
                    <Box display='flex'>
                        {resultChild} <Box component='img' src={NewBadge} minWidth='26px' ml='6px' />
                    </Box>
                );
            case 'region':
                switch (resultChild) {
                    case 2:
                        return <Box>{'North America'}</Box>;
                    case 3:
                        return <Box>{'Europe'}</Box>;
                    default:
                        return <Box>{'Unknown region'}</Box>;
                }
            default:
                if (outerGetCellRendererValueProcessor) {
                    return outerGetCellRendererValueProcessor(outerProps)(innerProps);
                }
                throw new Error(
                    `The component ${component} is not supported by the screener renderer result child wrapper.`,
                );
        }
    };
};

type ExtractFromScreenerDataParams = {
    screenerData: ScreenerEtfData | ScreenerData | ScreenerResearchData | ScreenerResearchCompanyData;
    cardName: string;
    filterKeysToRowLevelFilter?: ReturnType<typeof getFilterKeysToRowLevelFilter>;
    outerGetCellRendererValueProcessor?: CellRendererValueProcessorGetter;
    keepNoLeftPadding?: boolean;
    keepNoSidePaddings?: boolean;
    columnToCellClassRules?: Record<string, Record<string, (params: any) => boolean>>;
    breakpointsConfig?: [{ from: number; to: number; isActive: boolean }];
};

function determineTooltipValueGetter({
    noResultsSymbol,
    fieldViewdata,
}: {
    noResultsSymbol: string;
    fieldViewdata: ViewdataFieldDefinition;
}): (params: ITooltipParams) => string {
    return (params: ITooltipParams) => {
        const tooltipProps = fieldViewdata.tooltip_props;
        const _getValuesByPath = tooltipProps?.dots_independent_values_path
            ? getValuesByPathDotsIndependent
            : getValuesByPath;

        if (tooltipProps && tooltipProps.show_only_if_single_value && tooltipProps.values_path) {
            const numberOfValues = _getValuesByPath(params.data, tooltipProps.values_path).length;

            if (numberOfValues > 1) {
                return '';
            }
        }

        if (tooltipProps?.dots_independent_values_path) {
            const result = getValuesByPathDotsIndependent(params.data, tooltipProps.dots_independent_values_path);

            if (result.length === 0 || result.length > 1) {
                return '';
            }

            return result[0];
        }

        if (params.valueFormatted === noResultsSymbol) {
            return '';
        }

        return params?.valueFormatted || '';
    };
}

export function handlePopoverLink(props: ICellRendererParams) {
    const { valueFormatted, value, context, data } = props;
    const displayValue = valueFormatted || value;

    const renderValue = (content: string) => (
        <Box paddingX='24px'>{content}</Box>
    );

    if (displayValue === defaultNoResultsSymbol) {
        return renderValue(displayValue);
    }

    const requestParams = context?.getRequestParams?.(data || {}) || [];
    if (requestParams.length > 0) {
        return <PopoverGrid cellParams={props} requestParams={requestParams} />;
    }

    return renderValue(displayValue);
}

export function extractFromScreenerData({
    screenerData,
    cardName,
    filterKeysToRowLevelFilter,
    outerGetCellRendererValueProcessor,
    keepNoLeftPadding,
    keepNoSidePaddings,
    columnToCellClassRules,
    breakpointsConfig,
}: ExtractFromScreenerDataParams): {
    minWidths: Record<string, number>;
    customFlexibleColumns: Array<string>;
    columnDefs: Array<ColumnDef>;
} {
    const minWidths: Record<string, number> = {};
    const customFlexibleColumns: Array<string> = [];

    function fillMinWidth(fieldViewdata: ViewdataFieldDefinition, headerName: string) {
        if (fieldViewdata.min_width && typeof fieldViewdata.min_width === 'number') {
            minWidths[headerName] = fieldViewdata.min_width;
        }
    }

    function fillCustomFlexibleColumns(fieldViewdata: ViewdataFieldDefinition, headerName: string) {
        if (typeof fieldViewdata.custom_flex === 'boolean' && fieldViewdata.custom_flex) {
            customFlexibleColumns.push(headerName);
        }
    }

    const columnDefs: Array<ColumnDef> = [];

    sortViewdataAsc(screenerData._viewdata.fields).forEach((fieldDict) => {
        const field = Object.keys(fieldDict)[0];
        const fieldViewdata = fieldDict[field];

        const shouldHideOnCurrentScreenWidth = breakpointsConfig?.some(
            (breakpoint) =>
                fieldViewdata?.hide_on_ranges?.find(
                    (backendRangeConfig) =>
                        backendRangeConfig.from >= breakpoint.from &&
                        backendRangeConfig.to <= breakpoint.to &&
                        breakpoint.isActive,
                ) !== undefined,
        );

        if ((fieldViewdata.hide && !fieldViewdata.keep_in_grid) || shouldHideOnCurrentScreenWidth) {
            return;
        }

        const fieldMetadata = screenerData._metadata?.fields.filter((dict) => Object.keys(dict)[0] === field)[0][field];

        const headerTemplate = getHeaderTemplate(fieldMetadata.header_template, fieldMetadata.symbol);

        const headerNameFromMetadata = getHeaderName(fieldMetadata, headerTemplate);
        const hederNameFromViewData = fieldViewdata.header_name;
        const headerName = hederNameFromViewData || headerNameFromMetadata;

        fillMinWidth(fieldViewdata, headerName);
        fillCustomFlexibleColumns(fieldViewdata, headerName);

        const noResultsSymbol = fieldViewdata.no_results_symbol || defaultNoResultsSymbol;

        function cellRendererGetter() {
            if (fieldViewdata?.popover_table_link) {
                return (props: ICellRendererParams) => handlePopoverLink(props);
            }

            if (fieldViewdata.link) {
                return getCompanyDetailsLinkRenderer({
                    cfraIdPath: 'id',
                    cardName,
                    urlLinkPattern: fieldMetadata.url_link?.pattern,
                    target: fieldViewdata.link_target,
                    linkAnalyticsActionData: fieldViewdata.link_analytics_action_data,
                });
            }

            if (!fieldViewdata.cell_renderer_params) {
                return;
            }

            const objectWithFilter = getObjectWithFilter(fieldViewdata.cell_renderer_params);

            if (objectWithFilter && filterKeysToRowLevelFilter) {
                return getRowLevelFilterCellRenderer({
                    cellRendererParam: objectWithFilter,
                    filterKeysToRowLevelFilter,
                    fieldMetadata,
                    screenerData,
                });
            }

            return getCellRenderer({
                cellRendererParams: fieldViewdata.cell_renderer_params,
                cardName: cardName,
                urlLinkPattern: fieldMetadata.url_link?.pattern,
                cellRendererValueProcessor: getCellRendererValueProcessor({
                    fieldMetadata,
                    fieldViewdata,
                    outerGetCellRendererValueProcessor,
                }),
                noResultsSymbol,
            });
        }

        const valueFormatter = getScreenerValueFormatter(
            {
                ...fieldMetadata,
                value_template: fieldViewdata.value_template || fieldMetadata.value_template,
                ...(fieldViewdata.format && { format: fieldViewdata.format }),
            },
            screenerData,
            noResultsSymbol,
            fieldViewdata.use_dots_independent_value_getter ? getValuesByPathDotsIndependent : undefined,
        );

        let result: ColumnDef = {
            headerName: headerName,
            field: field,
            filter: 'agTextColumnFilter',
            cellRenderer: cellRendererGetter(),
            valueFormatter: valueFormatter,
            flex: fieldViewdata.flex,
            headerClass: fieldViewdata.header_class,
            cellClass: fieldViewdata.cell_class,
            cellRendererParentClass: fieldViewdata.cell_renderer_parent_class,
            sort: fieldViewdata.default_sort_order,
            maxWidth: fieldViewdata.max_width,
            pinned: fieldViewdata.pinned,
            comparator: (valueA, valueB, nodeA, nodeB) => {
                if (fieldMetadata.type === 'date' || fieldMetadata.type === 'number') {
                    if (!valueA && valueB) return 1;
                    if (valueA && !valueB) return -1;
                }

                if (fieldMetadata.type === 'date') {
                    const dateFormat = fieldMetadata.input_format || standardDateFormat;
                    return getMomentObjectFrom(valueA, dateFormat).isAfter(getMomentObjectFrom(valueB, dateFormat))
                        ? 1
                        : -1;
                }

                if (fieldMetadata.type === 'number') {
                    return valueA > valueB ? 1 : -1;
                }

                // convert to string
                const formattedValueA = String(
                    valueFormatter({ data: nodeA.data, colDef: result } as ValueFormatterParams),
                );
                const formattedValueB = String(
                    valueFormatter({ data: nodeB.data, colDef: result } as ValueFormatterParams),
                );

                // always move down empty values
                if (formattedValueA === noResultsSymbol && formattedValueB !== noResultsSymbol) return 1;
                if (formattedValueB === noResultsSymbol && formattedValueA !== noResultsSymbol) return -1;

                return formattedValueA?.toLowerCase?.() > formattedValueB?.toLowerCase?.() ? 1 : -1;
            },
            exportType: fieldViewdata.export_cell_type,
            unSortIcon: fieldViewdata.show_unsort_icon,
            sortable: fieldViewdata.sortable,
            ignoreOnExport: fieldViewdata.ignore_on_export,
            resizable: fieldViewdata.resizable,
            cellStyle: fieldViewdata.cell_style,
            ...(keepNoSidePaddings || keepNoLeftPadding || columnToCellClassRules
                ? {
                      cellClassRules: {
                          ...(keepNoLeftPadding ? keepNoLeftPaddingCellClassRules : {}),
                          ...(keepNoSidePaddings ? keepNoSidePaddingsCellClassRules : {}),
                          ...(columnToCellClassRules !== undefined ? columnToCellClassRules[field] : {}),
                      },
                  }
                : {}),
            ...(fieldViewdata.use_tooltip && {
                tooltipValueGetter: determineTooltipValueGetter({ noResultsSymbol, fieldViewdata }),
                tooltipComponent: AgGridCustomTooltip,
                tooltipComponentParams: {
                    allowHtml: true,
                    ...(fieldViewdata.tooltip_layout_style
                        ? { tooltipLayoutStyle: fieldViewdata.tooltip_layout_style }
                        : {}),
                },
            }),
            ...(fieldViewdata?.header_value_getter_params && {
                headerValueGetter: (params) => {
                    const defaultHeaderName = params.colDef.headerName || '';

                    if (!fieldViewdata?.header_value_getter_params) {
                        return defaultHeaderName;
                    }

                    for (const value of fieldViewdata.header_value_getter_params) {
                        if (params.location === value.location) {
                            return value.header_name;
                        }
                    }

                    return defaultHeaderName;
                },
            }),
            suppressHeaderMenuButton: fieldViewdata.suppress_header_menu_button,
            // it is necessary to have hide: false by default, to make the reset columns work as expected with columns visibility
            hide: fieldViewdata.hide || false,
        };

        if (['number', 'number_nullable'].includes(fieldMetadata.type)) {
            result = {
                ...result,
                ...(!fieldViewdata.cell_type && {
                    type: 'rightAligned',
                }),
                filter: 'agNumberColumnFilter',
                filterValueGetter: getScreenerNumberFilterValueGetter(fieldMetadata),
                excelExportNumberFormat: getExcelNumberFormat(fieldMetadata),
            };
        }

        if (fieldMetadata.type === 'date') {
            result = {
                ...result,
                excelExportDateFormat: fieldMetadata.format
                    ? getExcelExportDateFormat(fieldMetadata.format)
                    : undefined,
            };
        }

        columnDefs.push(result);
    });

    return { minWidths, customFlexibleColumns, columnDefs };
}

function isLastRollingHours(date: moment.MomentInput, filter_params: ComponentFilterParams = {}) {
    const { hours = 0 } = filter_params;
    if (date && moment(date).isValid() && moment(date).isAfter(moment().subtract(hours, 'hours'))) {
        return true;
    }
    return false;
}
