import { Box, SvgIcon, ThemeProvider } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { SxProps, styled } from '@mui/material/styles';
import React from 'react';
import Tooltip from '../../components/dataDisplay/Tooltip';
import { tooltipTheme as defaultTooltipTheme } from '../../components/themes/theme';
import { ButtonProps } from './types';

export type ETFIconButtonProps = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    buttonStyles?: SxProps;
    className?: string;
    enabledcolor?: string;
    backgroundcolor?: string;
    hovercolor?: string;
    transform?: string;
} & ButtonProps;

type CustomIconButtonProps = {
    widthheightsize?: number; // lowercase to avoid Warning: React does not recognize the `widthHeightSize` prop on a DOM element.
} & IconButtonProps;

const CustomIconButton = styled(IconButton)<CustomIconButtonProps>(({ widthheightsize }) => ({
    width: widthheightsize,
    height: widthheightsize,
}));

const CustomIcon = styled(({ Icon, onClick, viewBoxSize, borderRadius, ...props }: ETFIconButtonProps) => (
    <SvgIcon component={Icon} viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`} fontSize={'inherit'} {...props} /> // need {...props} to make styles for disabled property work
))<ETFIconButtonProps>(({ disabled, borderRadius, enabledcolor = '#4375BB', backgroundcolor = '#007BB8', hovercolor, transform }) => ({
    color: disabled ? '#B1BCC9' : enabledcolor,
    '&:hover': {
        color: hovercolor || '#ffffff',
        backgroundColor: backgroundcolor,
    },
    borderRadius: borderRadius,
    transform,
}));

export function ETFIconButton({
    name,
    Icon,
    tooltipText = '', // need default value to avoid error in case if no tooltip - Type 'string | undefined' is not assignable to type 'NonNullable<ReactNode>'
    disabled,
    onClick,
    buttonFontSize,
    viewBoxSize = 40,
    borderRadius = 4,
    buttonStyles,
    tooltipTheme,
    enabledcolor,
    backgroundcolor,
    hovercolor,
    transform,
    ...props
}: ETFIconButtonProps) {
    return (
        <ThemeProvider theme={tooltipTheme || defaultTooltipTheme}>
            <Box {...props}>
                {/*need wrap into Box for setting up styles like padding*/}
                <Tooltip
                    title={tooltipText}
                    disableHoverListener={disabled || !tooltipText}
                    tooltipContainerStyles={{ display: 'flex', alignItems: 'center' }}>
                    {/*disabled makes button button disabled, but don't influence on the button color*/}
                    {/*when ETFMenu used - the onClick property overwrites by ETFMenu component*/}
                    <CustomIconButton
                        onClick={onClick}
                        disabled={disabled}
                        widthheightsize={buttonFontSize}
                        sx={{ fontSize: buttonFontSize, ...buttonStyles }}
                        aria-label={name}>
                        {/*disabled used for icon styles*/}
                        <CustomIcon
                            Icon={Icon}
                            disabled={disabled}
                            viewBoxSize={viewBoxSize}
                            borderRadius={borderRadius}
                            enabledcolor={enabledcolor}
                            backgroundcolor={backgroundcolor}
                            hovercolor={hovercolor}
                            transform={transform}
                        />
                    </CustomIconButton>
                </Tooltip>
            </Box>
        </ThemeProvider>
    );
}
