import { ColumnDef } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { ScreenerHome } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/ScreenerHome';
import {
    FiltersModalContext,
    FiltersModalContextProvider,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { useFiltersForm } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { DataItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { getFiltersReqBody } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { Box } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useContext, useMemo } from 'react';
import { PageNames } from 'utils';
import { EmailMetricsFilters } from './EmailMerticsFilters';

const modifyColumnDef = (columnDef: ColumnDef[]): ColumnDef[] => {
    return columnDef.map((col) => ({
        ...col,
        cellRendererParams: {
            ...(col.cellRendererParams || {}),
            context: {
                ...(col?.cellRendererParams?.context || {}),
                getRequestParams: (rowData: DataItem) => getRequestParamsByFieldId(rowData, col?.field),
            },
        },
    }));
};

export function EmailMetrics() {
    const { filtersPostData } = useContext(FiltersModalContext);
    const { filtersFormJsx, filtersChipPanelJsx } = useFiltersForm({
        filtersRequestParams: [
            {
                type: 'filter',
                noErrorOnNoKeyValuePairs: true,
                requestBody: {},
                config: {},
            },
            {
                requestType: RequestTypes.POST,
                path: 'internal/screener-filters/alert',
                queryKeyFirstElement: 'alertFiltersQuery',
                apiName: ApiNames.PlatformManagementInternal,
            },
        ],
        getFiltersJsx: EmailMetricsFilters,
        chipStyles: { containerSx: { padding: '0', maxHeight: '56px', overflowY: 'auto' } },
        formStyle: { marginBottom: '6px' },
        useSubmitInFilters: true,
    });

    const requestBody = useMemo(() => {
        const _filtersPostData = cloneDeep(filtersPostData) || {};
        let updatedFilters = _filtersPostData;

        const alertTimeFilter = _filtersPostData?.['user_management.alert.alert_time'];
        if (alertTimeFilter) {
            const dateSentFilter = [
                { 'user_management.alert.alert_time': alertTimeFilter },
                { 'user_management.alert.alert_scheduled_time': alertTimeFilter },
            ];
            delete _filtersPostData['user_management.alert.alert_time'];
            updatedFilters = { $and: [{ $or: dateSentFilter }] };
            if (Object.keys(_filtersPostData).length > 0) {
                updatedFilters?.$and?.push(_filtersPostData);
            }
        }

        return getFiltersReqBody(updatedFilters);
    }, [filtersPostData]);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    width: '100%',
                    padding: '16px 24px 6px 24px',
                    marginBottom: '12px',
                    minHeight: '132px',
                }}>
                {filtersFormJsx}
                {filtersChipPanelJsx}
            </Box>

            {filtersFormJsx && (
                <ScreenerHome
                    cardName={PageNames.EmailMetrics}
                    modals={[]}
                    screenerRequestParams={{
                        path: 'alert',
                        view: 'alerts_metrics',
                        reportType: 'alerts_metrics',
                        size: 25,
                        requestBody,
                    }}
                    searchPlaceholder='Search for Emails'
                    useSSRMode={true}
                    modifyColumnDef={modifyColumnDef}
                />
            )}
        </>
    );
}

export const EmailMetricsWrapper = () => (
    <ResultsContextProvider>
        <FiltersModalContextProvider>
            <EmailMetrics />
        </FiltersModalContextProvider>
    </ResultsContextProvider>
);

export function getRequestParamsByFieldId(rowData: DataItem, fieldId?: string) {
    const alertId = rowData?.id;
    if (!alertId || !fieldId) return [];

    const commonRequestConfig = {
        requestType: RequestTypes.POST,
        queryKeyFirstElement: fieldId,
        apiName: ApiNames.PlatformManagementInternal,
    };

    const fieldRequestMap = {
        report_links_click_count: {
            reportType: 'report_link_clicks',
            view: 'email_link_clicks',
            orderBy: 'alert.email_link_click_count',
            sortDirection: 'desc',
            requestBody: getFiltersReqBody({ 'user_management.alert.id': { values: [alertId] } }),
            config: {
                path: 'internal/screener/alert',
                apiName: ApiNames.PlatformManagementInternal,
            },
        },
        templated_links_click_count: {
            reportType: 'templated_link_clicks',
            view: 'email_link_clicks',
            orderBy: 'alert.email_link_click_count',
            sortDirection: 'desc',
            requestBody: getFiltersReqBody({ 'user_management.alert.id': { values: [alertId] } }),
            config: { path: 'internal/screener/alert' },
        },
        bounces_count: {
            reportType: 'action_user_details',
            view: 'action_user_details',
            requestBody: getFiltersReqBody({
                'user_management.user_alert.alert_id': { values: [alertId] },
                'user_management.user_alert_action.action_type_lid': { values: [5] },
            }),
            config: { path: 'internal/screener/user_alert' },
        },
        complains_count: {
            reportType: 'action_user_details',
            view: 'action_user_details',
            requestBody: getFiltersReqBody({
                'user_management.user_alert.alert_id': { values: [alertId] },
                'user_management.user_alert_action.action_type_lid': { values: [6] },
            }),
            config: { path: 'internal/screener/user_alert' },
        },
    };

    const requestParams = fieldRequestMap[fieldId as keyof typeof fieldRequestMap];
    if (!requestParams) return [];

    const { config: reqConfig, ...restParams } = requestParams;
    return [restParams, { ...commonRequestConfig, ...reqConfig }];
}
