import { NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerFiltersChipPanel } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultPanelRow';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { ScreenerChipThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { ChipItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/types';
import {
    formatQuerystringDataToDirtyData,
    getDirtyData,
    getPostAndChipsData,
    RhFormData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { Box, Skeleton, SxProps, Theme } from '@mui/material';
import {
    CSSProperties,
    MutableRefObject,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Control, FieldValues, useForm, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { UseQueryResult } from 'react-query';
import { SendSingleRequest } from '../../api/screener';
import { FiltersModalContext } from '../../filtersModal/FiltersModalContext';
import { getDefaultValues } from '../../screenerFormElements/shared';

type UseFiltersFormOutputProps = {
    filtersFormJsx?: JSX.Element;
    filtersChipPanelJsx?: JSX.Element;
    filtersData?: FiltersData;
    submitHandler?: () => void;
    control?: Control<FieldValues, any>;
};

export type UseFiltersFormExternalInputProps = {
    externalChipItems?: Record<string, ChipItem>;
    onExternalChipDeleteClick?: (key: string) => void;
};

export type UseFiltersFormInputProps = {
    filtersRequestParams: Parameters<SendSingleRequest>;
    getFiltersJsx: (params: {
        submitHandler: () => void;
        control: Control;
        filtersData: FiltersData;
        getValues: UseFormGetValues<FieldValues>;
        setValue: UseFormSetValue<FieldValues>;
        validate: (fieldName: string) => Promise<boolean | undefined>;
        onChipClearAllClickRef: RefObject<() => void>;
    }) => JSX.Element;
    getFiltersDefaultValue?: () => Record<string, any>;
    chipStyles?: {
        containerSx?: SxProps;
        onChipItemsExistSxProps?: SxProps;
    };
    skeletonContainerSx?: SxProps;
    formStyle?: CSSProperties;
    externalFormStateStorage?: MutableRefObject<{
        dirtyFields: Record<string, boolean>;
        submittingData: Record<string, any>;
    } | null>;
    useSubmitInFilters?: boolean;
    chipTheme?: Theme;
} & UseFiltersFormExternalInputProps;

export function useFiltersForm({
    filtersRequestParams,
    externalChipItems,
    onExternalChipDeleteClick,
    getFiltersJsx,
    getFiltersDefaultValue,
    chipStyles,
    skeletonContainerSx,
    formStyle,
    externalFormStateStorage,
    useSubmitInFilters = true,
    chipTheme,
}: UseFiltersFormInputProps): UseFiltersFormOutputProps {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const { setFiltersPostData } = useContext(FiltersModalContext);
    const [filtersData, setFiltersData] = useState<FiltersData | undefined>(undefined);
    const [submittingData, setSubmittingData] = useState<{ [key: string]: any } | null>(null);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const filtersDataUseQueryResult = sendSingleRequest(...filtersRequestParams) as
        | UseQueryResult<FiltersData>
        | undefined;

    const {
        chipStateManager: { chipState, chipStateDispatcher },
        chipEventsManager: { onChipClearAllClick },
    } = useContext(ResultsContext);

    const onChipClearAllClickRef = useRef(onChipClearAllClick);

    const {
        control,
        formState: { dirtyFields: dirtyFieldsUseForm, errors, isValidating },
        getValues,
        setValue,
        handleSubmit,
        trigger,
        reset,
        resetField,
    } = useForm({
        reValidateMode: 'onSubmit',
    });

    const getDirtyFields = useCallback(() => {
        return {
            ...(externalFormStateStorage?.current?.dirtyFields || {}),
            ...dirtyFieldsUseForm,
        };
    }, [dirtyFieldsUseForm, externalFormStateStorage]);

    // apply externalFormStateStorage on first render
    useEffect(() => {
        if (!externalFormStateStorage?.current?.submittingData) {
            return;
        }

        // need to get default values to set them before setting submitting data, otherwise it will assume the first set data as a default value
        const defaultValues = getDefaultValues(externalFormStateStorage.current?.dirtyFields);

        // set default values
        reset(defaultValues);

        Object.keys(externalFormStateStorage.current.submittingData).forEach((key) => {
            if (externalFormStateStorage.current?.dirtyFields?.[key] === undefined) {
                return;
            }

            // set actual value from the externalFormStateStorage storage
            setValue(key, externalFormStateStorage.current.submittingData[key]);
        });
    }, [externalFormStateStorage, reset, setValue, getValues]);

    const validate = useCallback(
        async (fieldName: string) => {
            return await trigger?.(fieldName);
        },
        [trigger],
    );

    useEffect(() => {
        if (filtersDataUseQueryResult?.data && !filtersData) {
            setFiltersData(filtersDataUseQueryResult.data);
        }
    }, [filtersDataUseQueryResult, filtersData]);

    // modify externalFormStateStorage if it is passes to the component
    const handleExternalFormStateStorage = useCallback(
        ({
            dirtyFields,
            submittingData,
            reset,
        }: {
            dirtyFields?: Record<string, boolean>;
            submittingData?: Record<string, any>;
            reset?: boolean;
        }) => {
            if (externalFormStateStorage?.current === undefined) {
                return;
            }

            if (reset) {
                externalFormStateStorage.current = null;
            }

            if (!dirtyFields || !submittingData) {
                return;
            }

            externalFormStateStorage.current = {
                dirtyFields,
                submittingData,
            };
        },
        [externalFormStateStorage],
    );

    useEffect(() => {
        if (!filtersData || !getFiltersDefaultValue) return;

        const filtersDefaultValues = getFiltersDefaultValue();

        // Transform default filters values into dirtyData
        const dirtyData = Object.entries(filtersDefaultValues).reduce((acc, [filterKey, filterValue]) => {
            if (Array.isArray(filterValue)) {
                filterValue = filterValue.join(',');
            }
            return filterValue
                ? { ...acc, ...formatQuerystringDataToDirtyData(filterKey, filterValue, filtersData) }
                : acc;
        }, {} as Record<string, any>);

        if (Object.keys(dirtyData).length === 0) return;

        // set form value & mark fields as dirty
        const dirtyFields = Object.keys(dirtyData).reduce((acc, controlID) => {
            setValue(controlID, dirtyData[controlID]);
            acc[controlID] = true;
            return acc;
        }, {} as Record<string, boolean>);

        handleExternalFormStateStorage({ dirtyFields, submittingData: dirtyData });

        const { postData, chipItems } = getPostAndChipsData(dirtyData, filtersData);
        setFiltersPostData(postData);
        chipStateDispatcher({ type: 'SetChipsData', newState: { chipItems } });
        chipStateDispatcher({ type: 'SetFiltersDirtyData', newState: dirtyData });
    }, [
        chipStateDispatcher,
        filtersData,
        getFiltersDefaultValue,
        handleExternalFormStateStorage,
        setFiltersPostData,
        setValue,
    ]);

    useEffect(() => {
        if (chipState.action === 'Dirty' && filtersData) {
            let dirtyData = chipState.field.dirtyData;
            let controlID = chipState.field.controlID;

            if (dirtyData[controlID] === undefined) {
                resetField(controlID);
            } else {
                setValue(controlID, dirtyData[controlID]);
            }

            // write to externalFormStateStorage, on chip X button click
            handleExternalFormStateStorage({
                dirtyFields: getDirtyFields(),
                submittingData: dirtyData,
            });

            const { postData, chipItems } = getPostAndChipsData(dirtyData, filtersData);
            setFiltersPostData(postData);
            chipStateDispatcher({ type: 'SetChipsData', newState: { chipItems: chipItems } });
            chipStateDispatcher({ type: 'SetFiltersDirtyData', newState: dirtyData });
        }
    }, [
        resetField,
        setFiltersPostData,
        filtersData,
        setValue,
        chipState.field,
        externalFormStateStorage,
        chipStateDispatcher,
        chipState.action,
        getDirtyFields,
        handleExternalFormStateStorage,
    ]);

    useEffect(() => {
        if (chipState.action === 'Clear') {
            handleExternalFormStateStorage({ reset: true });

            if (externalFormStateStorage !== undefined) {
                const defaultValues = getDefaultValues(getValues());
                reset(defaultValues);
            } else {
                reset();
            }

            setFiltersPostData(undefined);
            chipStateDispatcher({ type: 'ClearAction' });
        }
    }, [
        reset,
        setFiltersPostData,
        chipState.action,
        getValues,
        chipStateDispatcher,
        externalFormStateStorage,
        handleExternalFormStateStorage,
    ]);

    useEffect(() => {
        const onSubmit = (data: RhFormData) => {
            const dirtyFields = getDirtyFields();

            handleExternalFormStateStorage({
                dirtyFields,
                submittingData: data,
            });
            const dirtyData = getDirtyData(dirtyFields, data);

            if (!filtersData) {
                return;
            }

            const { postData, chipItems } = getPostAndChipsData(dirtyData, filtersData);

            setFiltersPostData(postData);

            chipStateDispatcher({ type: 'SetChipsData', newState: { chipItems: chipItems } });
            chipStateDispatcher({ type: 'SetFiltersDirtyData', newState: dirtyData });
        };

        if (!isValidating && Object.keys(errors).length === 0 && submittingData) {
            onSubmit(submittingData);
            setSubmittingData(null);
        }
    }, [
        isValidating,
        errors,
        submittingData,
        getDirtyFields,
        filtersData,
        externalFormStateStorage,
        setFiltersPostData,
        chipStateDispatcher,
        handleExternalFormStateStorage,
    ]);

    const submitHandler = useCallback(() => {
        handleSubmit((data) => {
            setSubmittingData(data);
        })();
    }, [handleSubmit]);

    const reactNodes = useMemo(() => {
        if (!filtersData) {
            return null;
        }

        return getFiltersJsx({
            submitHandler: useSubmitInFilters ? submitHandler : () => {},
            control,
            filtersData,
            getValues,
            setValue,
            validate,
            onChipClearAllClickRef,
        });
    }, [
        control,
        filtersData,
        getValues,
        validate,
        submitHandler,
        useSubmitInFilters,
        setValue,
        onChipClearAllClickRef,
        getFiltersJsx,
    ]);

    if (filtersDataUseQueryResult?.isLoading && !filtersData) {
        return {
            filtersFormJsx: (
                <Box sx={{ width: '100%', ...skeletonContainerSx }}>
                    <Skeleton height='10px' />
                </Box>
            ),
        };
    }

    if (filtersDataUseQueryResult && !filtersDataUseQueryResult.data?.filter_metadata) {
        return { filtersFormJsx: <NoInformationAvailable emptyCardText='No filters information available.' /> };
    }

    if (!filtersData) {
        return {};
    }

    return {
        filtersFormJsx: <form style={{ marginBottom: '23px', ...formStyle }}>{reactNodes}</form>,
        filtersChipPanelJsx: (
            <ScreenerFiltersChipPanel
                key='ScreenerFilterChips'
                showClearButton={false}
                passedTheme={chipTheme || ScreenerChipThemeV2}
                externalChipItems={externalChipItems}
                externalOnChipDeleteClick={onExternalChipDeleteClick}
                containerSx={chipStyles?.containerSx}
                onChipItemsExistSxProps={chipStyles?.onChipItemsExistSxProps}
            />
        ),
        filtersData,
        submitHandler,
        control,
    };
}
