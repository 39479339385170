import { Typography } from '@cfra-nextgen-frontend/shared/src/components/Typography';
import { fontFamilies, fontWeights } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { styled } from '@mui/material';

export const TypographyStyle1 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '15px',
    color: '#002B5A',
}));

export const TypographyStyle2 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '18px',
    color: '#002B5A',
    letterSpacing: '0.1px',
}));

export const TypographyStyle3 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '14px',
    color: '#3c3c3c',
    letterSpacing: '0.1%',
}));

export const TypographyStyle4 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '13px',
    color: '#609EA0',
}));

export const TypographyStyle5 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '12px',
    color: '#007AB9',
}));

export const TypographyStyle6 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikSemibold,
    fontSize: '15px',
    color: '#002B5A',
}));

export const TypographyStyle7 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13.5px',
    color: '#3c3c3c',
}));

export const TypographyStyle8 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '11px',
    color: '#808080',
}));

export const TypographyStyle9 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '13px',
    color: '#5A5A5A',
}));

export const TypographyStyle10 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13.5px',
    color: '#007AB9',
}));

export const TypographyStyle11 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13.5px',
    color: '#555555',
}));

export const TypographyStyle12 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikSemibold,
    fontSize: '13.5px',
    color: '#007AB9',
}));

export const TypographyStyle13 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '18px',
    fontWeight: 500,
    color: '#002B5A',
    letterSpacing: '0.1%',
}));

export const TypographyStyle14 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontWeight: 400,
    fontSize: '14px',
    color: '#3c3c3c',
    letterSpacing: '0.1%',
}));

export const TypographyStyle15 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '13.5px',
    color: '#808080',
}));

export const TypographyStyle16 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '32px',
    color: '#DDDDDD',
}));

export const TypographyStyle17 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13.5px',
    color: '#007BB8',
}));

export const TypographyStyle18 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikLight,
    fontSize: '13px',
    color: '#808080',
}));

export const TypographyStyle19 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '16px',
    color: '#3C3C3C',
    lineHeight: '28px',
    fontWeight: 400,
}));

// Standard Small Gray Font
export const TypographyStyle20 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '12px',
    color: '#3C3C3C',
    fontWeight: 400,
}));

// Standard Medium Blue Font
export const TypographyStyle21 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '14px',
    color: '#002B5A',
}));

export const TypographyStyle22 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikSemibold,
    fontSize: '13.5px',
    color: '#3C3C3C',
}));

export const TypographyStyle23 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '10px',
    color: '#fff',
}));

export const TypographyStyle24 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '13.5px',
    color: '#007AB9',
}));

export const TypographyStyle25 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '16px',
    color: '#3078B5',
}));

export const TypographyStyle26 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '13.5px',
    color: '#333333',
}));

export const TypographyStyle27 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikBold,
    fontSize: '16px',
    color: '#002B5A',
}));

export const TypographyStyle28 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikLight,
    fontWeight: fontWeights.Light,
    fontSize: '44px',
    color: '#FFF',
}));

export const TypographyStyle29 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikSemibold,
    fontWeight: fontWeights.SemiBold,
    fontSize: '34px',
    color: '#002B5A',
}));

export const TypographyStyle30 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '16px',
    color: '#5A5A5A',
}));

export const TypographyStyle31 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontWeight: fontWeights.Medium,
    fontSize: '17px',
    color: '#002B5A',
}));

export const TypographyStyle32 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontWeight: fontWeights.Regular,
    fontSize: '15px',
    color: '#222222',
}));
export const TypographyStyle33 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '15px',
    color: '#555555',
}));

export const TypographyStyle34 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikBold,
    fontSize: '13.5px',
    color: '#555555',
}));

export const TypographyStyle35 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13.5px',
    color: '#555555',
}));

export const TypographyStyle36 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '13.5px',
    color: '#0B2958',
}));

export const TypographyStyle37 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikBlack,
    fontSize: '24px',
    color: '#182E58'
}));

export const TypographyStyle38 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '15px',
    color: '#555555'
}));

export const TypographyStyle39 = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikBold,
    fontSize: '15px',
    color: '#555555'
}));