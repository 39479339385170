import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, Button, CircularProgress, Popover } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { AgGridCard } from '../../AgGrid/AgGridCard';
import { ProjectSpecificResourcesContext } from '../../ProjectSpecificResourcesContext/Context';
import { SendSingleRequestConfig } from '../api/screener';
import { extractFromScreenerData, getCellRendererValueProcessor } from '../utils/columnDefs';

const DEFAULT_MIN_WIDTH = 150;

type PopoverGridProps = {
    requestParams: SendSingleRequestConfig[];
    cellParams: ICellRendererParams;
};

export function PopoverGrid({ requestParams, cellParams }: PopoverGridProps) {
    const value = cellParams.valueFormatted || cellParams.value;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const gridRef = useRef<AgGridReact>(null);
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const { data, isLoading, isFetched } = sendSingleRequest?.(
        {
            ...(requestParams?.[0] || {}),
            config: {
                enabled: open,
            },
        },
        {
            ...((requestParams?.[1] || {}) as SendSingleRequestConfig),
            requestType: RequestTypes.POST,
            apiName: ApiNames.PlatformManagementInternal,
        },
    ) as UseQueryResult<any>;

    const rowsData = useMemo(() => data?.results?.data || [], [data?.results?.data]);

    const { minWidths, columnDefs } = useMemo(() => {
        if (!data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }

        return extractFromScreenerData({
            screenerData: data,
            cardName: 'popover grid',
            outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
            keepNoSidePaddings: true,
        });
    }, [data]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? DEFAULT_MIN_WIDTH : minWidths[headerName] || DEFAULT_MIN_WIDTH,
        [minWidths],
    );

    return (
        <>
            <Button
                onClick={handleClick}
                disableRipple
                sx={{
                    paddingX: '24px',
                    minWidth: 'unset',
                    backgroundColor: 'transparent',
                    '&:hover': {
                        textDecoration: 'underline',
                        backgroundColor: 'transparent',
                    },
                }}>
                {isLoading ? <CircularProgress size={15} sx={{ color: 'primary.main' }} /> : value}
            </Button>
            <Popover
                open={open && !isLoading && isFetched}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                slotProps={{ paper: { sx: { boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)' } } }}>
                <Box sx={{ width: '100%', minWidth: 'min(500px, 80vw)', p: '10px', minHeight: '270px' }}>
                    <AgGridCard
                        ref={gridRef}
                        columnDefs={columnDefs}
                        rowsData={rowsData}
                        useSSRMode={false}
                        defaultMaxWidth={350}
                        getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                        maxNumberOfRowsToDisplay={5}
                    />
                </Box>
            </Popover>
        </>
    );
}
