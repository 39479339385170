import { AnalyticsTickerSearchContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/analyticsTickerSearchContext/Context';
import {
    CreateModal,
    CreateModalProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/CreateModal';
import { FiltersModalContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { MultipleModalsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/MultipleModalsContext/MultipleModalsContext';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { CreateForm, CreateFormProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/CreateForm';
import { invalidateQueriesByKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { SnackbarProvider } from 'notistack';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ScreenerCard, ScreenerCardProps } from './ScreenerCard';
import { RhFormData } from '../filtersModal/utils';
import { FiltersData } from '../../Form/types/filters';

export type ScreenerHomeProps = ScreenerCardProps & {};

export function ScreenerHome({
    cardName,
    searchPlaceholder,
    enableTopLeftActionPanel,
    enableTopRightActionPanel,
    useSSRMode,
    screenerRequestParams,
    analyticsCreateModalFormName,
    rowLevelFiltersConfig,
    maxGridContainerHeightPercentage,
    unlimitedCalculatedHeight,
    modals,
    showSelectionOnEdit,
    EditButtonsVariant,
    processDeleteAction,
    processAddAction,
    getRowClassRules,
    getConfirmationModalContent,
    getFormDataState,
    getDefaultView,
    getColumnOrderderedInEditMode,
    operationType,
    entityType,
    hardRefreshOnUpdate,
    setInitialEditStatus,
    modifyColumnDef
}: ScreenerHomeProps) {
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const groupTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [entityId] = useState<number | undefined>(id ? parseInt(id) : undefined);
    // Contains FormData from external source like modal pop here, which needs to be passed to screener card
    const [externalFormData, setExternalFormData] = useState<{
        filtersData: FiltersData;
        formData: RhFormData;
        formDataState: Record<string, any>;
    }>();

    const onCancel = useCallback(() => {
        setExternalFormData(undefined);
    }, [setExternalFormData]);

    const url = useLocation();

    const screenerCard = useMemo(() => {
        return (
            <ScreenerCard
                cardName={cardName}
                modals={modals}
                searchPlaceholder={searchPlaceholder}
                enableTopLeftActionPanel={enableTopLeftActionPanel}
                enableTopRightActionPanel={enableTopRightActionPanel}
                useSSRMode={useSSRMode}
                screenerRequestParams={screenerRequestParams}
                rowLevelFiltersConfig={rowLevelFiltersConfig}
                analyticsCreateModalFormName={analyticsCreateModalFormName}
                maxGridContainerHeightPercentage={maxGridContainerHeightPercentage}
                unlimitedCalculatedHeight={unlimitedCalculatedHeight}
                showSelectionOnEdit={showSelectionOnEdit}
                EditButtonsVariant={EditButtonsVariant}
                processDeleteAction={processDeleteAction}
                processAddAction={processAddAction}
                getRowClassRules={getRowClassRules}
                getConfirmationModalContent={getConfirmationModalContent}
                getFormDataState={getFormDataState}
                getDefaultView={getDefaultView}
                getColumnOrderderedInEditMode={getColumnOrderderedInEditMode}
                operationType={operationType}
                entityType={entityType}
                externalFormData={externalFormData}
                entityId={entityId}
                onCancel={onCancel}
                hardRefreshOnUpdate={hardRefreshOnUpdate}
                setInitialEditStatus ={setInitialEditStatus}
                modifyColumnDef={modifyColumnDef}
            />
        );
    }, [
        cardName,
        modals,
        searchPlaceholder,
        enableTopLeftActionPanel,
        enableTopRightActionPanel,
        useSSRMode,
        screenerRequestParams,
        analyticsCreateModalFormName,
        rowLevelFiltersConfig,
        maxGridContainerHeightPercentage,
        unlimitedCalculatedHeight,
        externalFormData,
        entityId,
        showSelectionOnEdit,
        EditButtonsVariant,
        processDeleteAction,
        processAddAction,
        getRowClassRules,
        getConfirmationModalContent,
        getFormDataState,
        getDefaultView,
        getColumnOrderderedInEditMode,
        operationType,
        entityType,
        onCancel,
        hardRefreshOnUpdate,
        setInitialEditStatus,
        modifyColumnDef
    ]);

    const multipleModalsJsx = useMemo(() => {
        if (!modals) {
            return null;
        }

        return modals.map(({ props, ModalWrapper }) => {
            const onCreationSuccess: CreateFormProps['onCreationSuccessCallback'] = (createdItemId?: number) => {
                invalidateQueriesByKey('getFiltersData');
                invalidateQueriesByKey('getScreenerData');

                if (!createdItemId) {
                    return;
                }

                if (props.entityType === 'group') {
                    if (groupTimeoutRef.current) {
                        clearTimeout(groupTimeoutRef.current);
                    }

                    groupTimeoutRef.current = setTimeout(() => navigate(`${url.pathname}/${props.entityType}/${createdItemId}`), 300);
                    return;
                }

                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }

                timeoutRef.current = setTimeout(() => navigate(`/${props.entityType}/${createdItemId}`), 300);
            };

            const onSubmit = (filtersData: FiltersData, formData: RhFormData, formDataState: Record<string, any>) => {
                setExternalFormData({ filtersData, formData, formDataState });
            };

            const modalProps: { key: string } & CreateModalProps = {
                key: props.title,
                modalTitle: props.title,
                entityType: props.entityType,
                entityId,
                onOperationSuccessSnackMessage: props.onOperationSuccessSnackMessage,
                submitButtonName: props.submitButtonName,
                onBeforeCreateSuccess: props.onBeforeCreateSuccess,
                onCreationSuccessCallback: onCreationSuccess,
                operationType: props.operationType,
                requestPath: props.requestPath,
                description: props.description,
                multipleResultsPopup: props.multipleResultsPopup,
                multipleResultsPopupId: props.multipleResultsPopupId,
                multipleResultsPopupTitle: props.multipleResultsPopupTitle,
                maxNumberOfItemsPerOneRequest: props.maxNumberOfItemsPerOneRequest,
                presetValues: props.presetValues,
                localFiltersMetadata: props.localFiltersMetadata,
                useScreenerDataForFilters: props.useScreenerDataForFilters,
                onSubmit,
                FormComponent: props.FormComponent || CreateForm,
            };

            if (ModalWrapper) {
                return <ModalWrapper {...modalProps} ModalComponent={CreateModal} />;
            }

            return <CreateModal {...modalProps} />;
        });
    }, [modals, entityId, navigate, url.pathname]);

    return (
        <ResultsContextProvider>
            <MultipleModalsContextProvider>
                <FiltersModalContextProvider>
                    <AnalyticsTickerSearchContextProvider>{screenerCard}</AnalyticsTickerSearchContextProvider>
                </FiltersModalContextProvider>
                {modals && (
                    <SnackbarProvider
                        maxSnack={3}
                        classes={{
                            containerRoot: 'cfra-snackbar-root',
                        }}>
                        {multipleModalsJsx}
                    </SnackbarProvider>
                )}
            </MultipleModalsContextProvider>
        </ResultsContextProvider>
    );
}
