import { RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import { ColumnDef } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { roundedTextButtonThemeV2BorderRadius4 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerHome } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/ScreenerHome';
import {
    FiltersModalContext,
    FiltersModalContextProvider,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { useFiltersForm } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { DataItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { getFiltersReqBody } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { Box } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { SnackbarProvider, useSnackbar, VariantType } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageNames } from 'utils/enums';
import { AlertDetailsFilters } from './EmailMerticsFilters';

const AUTO_HIDE_DURATION = 5000;

const FIELD_REQUEST_MAP = {
    report_links_click_count: {
        reportType: 'report_link_clicks',
        view: 'email_link_clicks',
        orderBy: 'user_alert.email_link_click_count',
        sortDirection: 'desc',
        requestBody: (alertId: string) => getFiltersReqBody({ 'user_management.user_alert.id': { values: [alertId] } }),
        config: {
            path: 'internal/screener/user_alert',
            apiName: ApiNames.PlatformManagementInternal,
        },
    },
    templated_links_click_count: {
        reportType: 'templated_link_clicks',
        view: 'email_link_clicks',
        orderBy: 'user_alert.email_link_click_count',
        sortDirection: 'desc',
        requestBody: (alertId: string) => getFiltersReqBody({ 'user_management.user_alert.id': { values: [alertId] } }),
        config: { path: 'internal/screener/user_alert' },
    },
};

export function AlertDetails() {
    const { id } = useParams<{ id: string }>();
    const requestBody = useUserAlertFilters(id);
    const { filtersFormJsx, filtersChipPanelJsx } = useFiltersForm({
        filtersRequestParams: [
            {
                type: 'filter',
                noErrorOnNoKeyValuePairs: true,
                requestBody: getFiltersReqBody(getUserAlertBaseFilter(id)),
                config: {},
            },
            {
                requestType: RequestTypes.POST,
                path: 'internal/screener-filters/user_alert',
                queryKeyFirstElement: 'userAlertFiltersQuery',
                apiName: ApiNames.PlatformManagementInternal,
            },
        ],
        getFiltersJsx: AlertDetailsFilters,
        chipStyles: { containerSx: { padding: '0', maxHeight: '56px', overflowY: 'auto' } },
        formStyle: { marginBottom: '6px' },
        useSubmitInFilters: true,
    });

    if (!id || isNaN(parseInt(id))) return null;

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    width: '100%',
                    padding: '16px 24px 6px 24px',
                    marginBottom: '12px',
                    minHeight: '132px',
                }}>
                {filtersFormJsx}
                {filtersChipPanelJsx}
            </Box>
            {filtersFormJsx && (
                <ScreenerHome
                    cardName={PageNames.Alert}
                    modals={[]}
                    screenerRequestParams={{
                        path: 'user_alert',
                        view: 'alert_user_details',
                        reportType: 'alert_user_details',
                        size: 25,
                        requestBody,
                    }}
                    searchPlaceholder='Search for Alerts'
                    useSSRMode={true}
                    modifyColumnDef={modifyColumnDef}
                />
            )}
        </>
    );
}

export const AlertDetailsWrapper = () => (
    <ResultsContextProvider>
        <FiltersModalContextProvider>
            <SnackbarProvider>
                <AlertDetails />
            </SnackbarProvider>
        </FiltersModalContextProvider>
    </ResultsContextProvider>
);

const useResendEmail = (props: ICellRendererParams) => {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [userAlertId, setUserAlertId] = useState<number | undefined>();
    const [disableBtn, setDisableBtn] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const response = sendSingleRequest(
        {
            requestBody: { user_alert_ids: [userAlertId] },
            config: { enabled: typeof userAlertId === 'number' },
            noErrorOnNoKeyValuePairs: true,
        },
        {
            requestType: RequestTypes.POST,
            path: 'internal/resend-email',
            queryKeyFirstElement: `resendEmail${props.data.id}`,
            apiName: ApiNames.PlatformManagementInternal,
        },
    );

    useEffect(() => {
        if (!response?.isLoading && response?.isFetched) {
            const data: any = response?.data || {};
            const isSuccess = data?.status === 'success';
            const message = isSuccess
                ? `Email was successfully Resent to User: ${props?.data?.user_email}`
                : `Failed to Resend Email to User: ${props?.data?.user_email}`;
            const variant: VariantType = isSuccess ? 'success' : 'error';

            enqueueSnackbar(message, { variant, autoHideDuration: AUTO_HIDE_DURATION });
            setUserAlertId(undefined);
            setTimeout(() => setDisableBtn(false), AUTO_HIDE_DURATION);
        }
    }, [enqueueSnackbar, props?.data?.user_email, response]);

    const handleOnClick = () => {
        if (props?.data?.id) {
            setDisableBtn(true);
            setUserAlertId(parseInt(props.data.id));
        }
    };

    return { disableBtn, userAlertId, handleOnClick };
};

const useUserAlertFilters = (id: string = '') => {
    const { filtersPostData } = useContext(FiltersModalContext);

    return useMemo(() => {
        if (!id) return undefined;

        const _filtersPostData = cloneDeep(filtersPostData) || {};

        const userAlertFilter = getUserAlertBaseFilter(id);

        const updatedFilters =
            Object.keys(_filtersPostData).length > 0 ? { $and: [_filtersPostData, userAlertFilter] } : userAlertFilter;

        return getFiltersReqBody(updatedFilters);
    }, [filtersPostData, id]);
};

function ResendEmailCellRenderer(props: ICellRendererParams) {
    const { disableBtn, userAlertId, handleOnClick } = useResendEmail(props);

    return (
        <RoundedTextButton
            type='submit'
            theme={roundedTextButtonThemeV2BorderRadius4}
            buttonText='Resend'
            disabled={disableBtn}
            isLoading={typeof userAlertId === 'number'}
            onClickCallback={handleOnClick}
            sx={{ width: 'unset', paddingX: '10px' }}
        />
    );
}

const modifyColumnDef = (columnDef: ColumnDef[]): ColumnDef[] => {
    const modifiedColumns = columnDef.map((col) => ({
        ...col,
        cellRendererParams: {
            ...(col.cellRendererParams || {}),
            context: {
                ...(col?.cellRendererParams?.context || {}),
                getRequestParams: (rowData: DataItem) => getRequestParamsByFieldId(rowData, col?.field),
            },
        },
    }));

    return [
        ...modifiedColumns,
        {
            field: 'actions',
            headerName: 'Action',
            cellRenderer: ResendEmailCellRenderer,
            width: 120,
            sortable: false,
            filter: false,
            resizable: false,
        },
    ];
};

export function getRequestParamsByFieldId(rowData: DataItem, fieldId?: string) {
    const alertId = rowData?.id;
    if (!alertId || !fieldId) return [];

    const requestParams = FIELD_REQUEST_MAP[fieldId as keyof typeof FIELD_REQUEST_MAP];
    if (!requestParams) return [];

    const { config: reqConfig, ...restParams } = requestParams;
    return [
        { ...restParams, requestBody: requestParams.requestBody(alertId) },
        {
            requestType: RequestTypes.POST,
            queryKeyFirstElement: fieldId,
            apiName: ApiNames.PlatformManagementInternal,
            ...reqConfig,
        },
    ];
}

const getUserAlertBaseFilter = (id: string = '') => {
    if (!id) return {};

    const parsedId = parseInt(id);
    if (isNaN(parsedId)) return {};

    return {
        'user_management.user_alert.alert_id': { values: [parsedId] },
    };
};
